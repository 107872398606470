.login-page {
  background-color: var(--primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.login-form {
  color: #d9d9d9;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.logo-mobile {
  height: 26px;
}

.icon {
  color: #c0c0c0;
}

.icon-lg {
  height: 80px;
  width: 80px;
}

.site-layout {
  transition: all 0.2s;
}

.ant-layout-header,
.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: var(--primary);
  background: var(--primary);
}

.ant-layout-sider-trigger {
  background: #402f59;
}

.ant-radio-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin: 5px;
  padding: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}

.ant-radio-wrapper:hover {
  border-color: #282c34;
}

.ant-radio-wrapper-checked {
  background-color: var(--primary);
  border-color: #282c34;
  color: white;
}

.ant-radio-inner {
  display: none;
}

/* .ant-btn:focus {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
} */

/* .ant-btn:focus {
  background-color: #1890ff !important;
} */

input,
select,
textarea {
  font-size: 16px !important;
}

.ant-select-selector {
  height: 34.5px !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
  font-weight: bold;
  text-shadow: 0 0 0.25px currentColor;
} */
